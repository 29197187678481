





























import { defineComponent, ref, computed } from 'nuxt-composition-api'
import PostEntity from '@/entities/Post'
import PostCard from '@/components/Modules/PostCard'

const PER_PAGE = 8

export default defineComponent({
  name: 'PostList',
  components: {
    PostCard
  },
  props: {
    data: {
      type: Array as () => PostEntity[],
      required: true as true
    }
  },
  setup(props) {
    const { data } = props

    // Data
    const page = ref(1)

    // Computed
    const isLastPage = computed(() => {
      return page.value * PER_PAGE >= data.length
    })

    // Methods
    const postPath = (post: PostEntity) => `/posts/${post.props.slug}`
    const loadNextPage = (state: any) => {
      if (isLastPage.value) return
      page.value = page.value + 1
    }
    const isVisible = (index: number) => index + 1 <= page.value * PER_PAGE

    return {
      postPath,
      loadNextPage,
      isLastPage,
      isVisible
    }
  }
})
