









































import { defineComponent, computed, ref, onMounted } from 'nuxt-composition-api'
import dayjs from 'dayjs'
import PostEntity from '@/entities/Post'
import { prerender as _prerender } from '@/util/util'
import Empty from '@/components/Base/Empty'
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver'

export default defineComponent({
  name: 'PostCard',
  props: {
    post: {
      type: Object as () => PostEntity,
      required: true
    }
  },
  components: {
    Empty
  },
  setup(props) {
    const { post } = props

    const isImageReady = ref(false)
    const rootRef = ref<HTMLElement>(null)

    // Computed
    const publishedAt = computed(() => {
      const publishedAt = dayjs(post.props.publishedAt)
      return publishedAt.format('MMM D, YYYY')
    })
    const headerImage = computed(() => {
      const { headerImage } = post.props
      return headerImage ? headerImage.fields.file.url : ''
    })
    const headerWebp = computed(() => {
      const { headerImageLight } = post.props
      return headerImageLight ? headerImageLight.fields.file.url : ''
    })

    // Methods
    const tagPath = (tag: string) => `/tags/${tag}`
    const prerender = () => {
      const href = `/posts/${post.props.slug}`
      _prerender(href)
    }
    const handleObservability = () => {
      try {
        useIntersectionObserver(rootRef, entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              isImageReady.value = true
              return
            }
          })
        })
      } catch (e) {
        isImageReady.value = true
        return
      }
    }

    // Lifecycle
    onMounted(() => {
      handleObservability()
    })

    return {
      publishedAt,
      headerImage,
      headerWebp,
      tagPath,
      prerender,
      isImageReady,
      rootRef
    }
  }
})
