




import { defineComponent } from 'nuxt-composition-api'

export default defineComponent({
  name: 'Empty'
})
