








import { defineComponent } from 'nuxt-composition-api'
import { usePresenter, PresenterFn } from '@/hooks/usePresenter'

import Presenter, { IPresenter, ListType } from './presenter'
import PostRepository from '@/repositories/PostRepository'
import PostList from '@/components/Modules/PostList'

export default defineComponent({
  name: 'Home',
  components: {
    PostList
  },
  setup() {
    const presenterFn: PresenterFn<IPresenter> = store =>
      Presenter({
        postRepository: new PostRepository(store),
        listType: ListType.Latest
      })
    const presenter = usePresenter(presenterFn)

    return {
      presenter
    }
  }
})
